import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { GlobalStyles } from '@mui/material';

import Start from './Start';
import CauseDamageCategory from './CauseDamage';
import CauseDamageAanrijding from './CauseDamage/aanrijding';
import CauseDamageAnders from './CauseDamage/anders';
import AnimalOwnerKnown from './AnimalOwnerKnown';
import CounterPartyKnown from './CounterPartyKnown';
import WitnessKnown from './WitnessKnown';
import WitnessAddress from './WitnessAddress';
import WitnessContact from './WitnessContact';
import WitnessName from './WitnessName';
import WitnessPostal from './WitnessPostal';
import Speed from './Speed';
import Interior from './Interior';
import Exterior from './Exterior';
import CauseDamageDropdpwn from './CauseDamageDropdown'
import GlassDamageSort from './GlassDamageSort'
import CauseDamageDropdownAanrijding from './CauseDamageDropdown/aanrijding';
import CauseDamageDropdownAnders from './CauseDamageDropdown/anders';
import CauseDamageGlassOverig from './CauseDamageGlass/overig'
import routes from './routes';
import { useScaling } from '@domain/hooks';
import DamageType from './DamageType';
import PolicyNumber from './PolicyNumber';
import DriveableDamage from './DriveableDamage';
import Mileage from './Mileage';
import DamageNumber from './DamageNumber';
import CarOwner from './CarOwner';
import LeasingCompany from './LeasingCompany';
import PayingDamage from './PayingDamage';
import RecoverableDamage from './RecoverableDamage';
import PossibleWBF from './PossibleWBF';
import InsuranceType from './InsuranceType';
import ExtraDamage from './ExtraDamage';
import CarInfo from './CarInfo';
import CauseDamageCombinedDropdown from './CauseDamageCombinedDropdown'
import CreatedByDealer from './CreatedByDealer';
import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath, chooseRedirectPath } from '@domain/helpers';
import ExtraDamageDescription from './ExtraDamageDescription';
import { useLocation } from 'react-router-dom';
import damageRoute from '../routes';
import onboardingRoute from '../../routes';
import AlcoholInvolved from './AlcoholInvolved';
import PolicePresent from './PolicePresent';

const damageStyle = `
  .main__damage {
    .h1 {
      margin-bottom: 42px;
    }
  }
  .screen-desktop & {
    .main__damage {
      .grid__column--main {
        padding-right: 0;
      }
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(50)}px;
        &.dropdown {
          margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px
        }
      }
      h1 + .button {
        margin-top: ${props => props.$scaling.scaleUpTo4K(70)}px;
      }
    }
  }
  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .main__damage {
        .grid__column--main {
          padding-right: 0;
        }
        .damage-region {
          margin: auto;
        }
        h1 {
          margin: 0 auto 50px;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          // display:block;
          legend {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .main__damage {
        h1 {
          margin-left: 0;
        }
      }
    }
    .screen-ie & {
      .main__damage {
        .grid-container__main {
          legend {
            text-align: left;
          }
        }
      }
    }
  }
`;

const personalStyle = `
  .main__personal {
    .h1 {
      margin-bottom: 42px;
    }
  }

  .screen-desktop & {
    .main__personal {
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      .h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px;
        // max-width: ${props => props.$scaling.scaleUpTo4K(448)}px;
      }
    }
    .main__personal--gender {
      .h1 {
        // so that the heading is at the same vertical position then on the textInputPage before
        // margin-bottom: ${props => props.$scaling.scaleUpTo4K(93)}px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .main__personal {
        .h1 {
          margin-bottom: 50px;
          // max-width: none;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .form {
        flex-wrap: wrap;
        .form-input {
          flex: 1 1 100%;
          padding: 0 !important;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          text-align: left;
        }
      }
    }
  }
`;

function Damage() {
  const scaling = useScaling();
  const bsLists = useSelector(state => state.bodyshopLists);

  useEffect(() => {
    if (!bsLists) {
      return;
    }
  }, [bsLists]);

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const { damage } = damageRoute;
  const { onboarding } = onboardingRoute;

  const location = useLocation()

  const validPath = (!!questionnaire && !!questionnaire.find(page => page.path === location.pathname))
    || (location.pathname === (onboarding + damage + routes.causeDamageDropDown + routes.causeDamageAanrijding))
    || (location.pathname === (onboarding + damage + routes.causeDamageDropDown + routes.causeDamageAnders))
    || (location.pathname === (onboarding + damage + routes.causeDamageDropDown + routes.causeDamageOverig))
    || (location.pathname === (onboarding + damage + routes.causeDamageAanrijding))
    || (location.pathname === (onboarding + damage + routes.causeDamageAnders))
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  if (!!questionnaire && questionnaire.length && !validPath) {
    return <Navigate to={chooseRedirectPath(questionnaire, newValidPath)} replace={true} />
  }

  return (
    <>
      <Routes>

        <Route path={routes.start} element={<Start />} />
        <Route
          path={routes.causeDamageCategory}
          element={<CauseDamageCategory />}
        />
        <Route
          path={routes.causeDamageAanrijding}
          element={<CauseDamageAanrijding />}
        />
        <Route
          path={routes.causeDamageAnders}
          element={<CauseDamageAnders />}
        />
        <Route path={routes.animalOwnerKnown} element={<AnimalOwnerKnown />} />
        <Route path={routes.carInfo} element={<CarInfo />} />
        <Route path={routes.createdByDealer} element={<CreatedByDealer />} />
        <Route path={routes.counterPartyKnown} element={<CounterPartyKnown />} />
        <Route path={routes.witnessKnown} element={<WitnessKnown />} />
        <Route path={routes.witnessAddress} element={<WitnessAddress />} />
        <Route path={routes.witnessContact} element={<WitnessContact />} />
        <Route path={routes.witnessName} element={<WitnessName />} />
        <Route path={routes.witnessPostal} element={<WitnessPostal />} />
        <Route path={routes.speed} element={<Speed />} />
        <Route path={routes.interior} element={<Interior />} />
        <Route path={routes.exterior} element={<Exterior />} />
        <Route path={routes.glassDamageSort} element={<GlassDamageSort />} />
        <Route path={routes.causeDamageDropDown} element={<CauseDamageDropdpwn />} />
        <Route path={routes.causeDamageCombinedDropDown} element={<CauseDamageCombinedDropdown />} />
        <Route path={routes.carOwner} element={<CarOwner />} />
        <Route path={routes.leasingCompany} element={<LeasingCompany />} />
        <Route path={routes.payingDamage} element={<PayingDamage />} />
        <Route path={routes.recoverableDamage} element={<RecoverableDamage />} />
        <Route path={routes.possibleWBF} element={<PossibleWBF />} />
        <Route path={routes.insuranceType} element={<InsuranceType />} />
        <Route path={routes.extraDamage} element={<ExtraDamage />} />
        <Route path={routes.extraDamageDescription} element={<ExtraDamageDescription />} />
        <Route path={routes.policyNumber} element={<PolicyNumber />} />
        <Route path={routes.driveabledamage} element={<DriveableDamage />} />
        <Route path={routes.vehicleMileage} element={<Mileage />} />
        <Route path={routes.damageNumber} element={<DamageNumber />} />
        <Route path={routes.alcoholInvolved} element={<AlcoholInvolved />} />
        <Route path={routes.policeAtAccident} element={<PolicePresent />} />
        <Route
          path={routes.causeDamageDropDown + routes.causeDamageAanrijding}
          element={<CauseDamageDropdownAanrijding />}
        />
        <Route
          path={routes.causeDamageDropDown + routes.causeDamageAnders}
          element={<CauseDamageDropdownAnders />}
        />
        <Route
          path={routes.causeDamageDropDown + routes.causeDamageOverig}
          element={<CauseDamageGlassOverig />}
        />
        <Route path={routes.damageType} element={<DamageType />} />
        <Route path='*' element={<Navigate to={routes.start} />} />
      </Routes>
      <GlobalStyles scaling={scaling} styles={personalStyle} />
      <GlobalStyles scaling={scaling} styles={damageStyle} />
    </>
  );
}

export default Damage;
