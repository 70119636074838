import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useSaveIncidentFactory } from '@domain/hooks';
import { getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { PostalPage } from '@domain/pages';
import { companyNames, proccessType } from '@domain/constants'
import { Loader } from '@domain/components';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

function Postal() {

  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const inCreation = useSelector(state => state.inCreation);
  const insurer = useSelector(state => state.insurer)
  const files = useSelector((state) => state.files)

  const isVIP = (insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand))
  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);
  const themeContext = useTheme();

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath)
      browserStorage.setProgress(progress.name);
      browserStorage.setProgressIndex(progress.index);
    }
  }, [currentPath, questionnaire])

  if (!insurer || !questionnaire || !incident) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, inCreation, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const heading = content["title"]
  const faqs = content["faqs"]
  const nextButtonText = content["button-text"]

  const buttonText = nextButtonText ? nextButtonText : !!inCreation ? 'Volgende' : 'Correct'
  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  return (
    <PostalPage
      buttonText={buttonText}
      currentPath={currentPath}
      faqs={faqs}
      formID="postal"
      heading={heading}
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      isVIP={isVIP}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
      customMiddleLogo={useCustomLogo}
      middleLogo={middleLogo}
      files={files}
    />
  );
}

export default Postal;
