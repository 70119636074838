// NPM imports.
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Comment from './Comment';
import ServiceAdvisorName from './ServiceAdvisorName'

import routes from './routes';
import { useSelector } from 'react-redux';
import { Loader } from '@domain/components';
import { GlobalStyles } from '@mui/material';
import { useScaling } from '@domain/hooks';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath, chooseRedirectPath } from '@domain/helpers';
import { useLocation } from 'react-router-dom';
const advisorStyle = `
  .main__personal {
    .h1 {
      margin-bottom: 42px;
    }
  }

  .screen-desktop & {
    .main__personal {
      .legend {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(24)}px;
      }
      .h1 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(70)}px;
        // max-width: ${props => props.$scaling.scaleUpTo4K(448)}px;
      }
    }
    .main__personal--gender {
      .h1 {
        // so that the heading is at the same vertical position then on the textInputPage before
        // margin-bottom: ${props => props.$scaling.scaleUpTo4K(93)}px;
      }
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .main__personal {
        .h1 {
          margin-bottom: 50px;
          // max-width: none;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .form {
        flex-wrap: wrap;
        .form-input {
          flex: 1 1 100%;
          padding: 0 !important;
        }
      }
    }
    .screen-ie & {
      .main__personal {
        .legend {
          text-align: left;
        }
      }
    }
  }
`;

function Advisor() {
  const insurer = useSelector(state => state.insurer)
  const location = useLocation()
  const scaling = useScaling()
  const { serviceAdvisorName, comment } = routes;

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

  if (!insurer) {
    return <Loader />;
  }
  if (!!questionnaire && questionnaire.length && !validPath) {
    return <Navigate to={chooseRedirectPath(questionnaire, newValidPath)} replace={true} />
  }
  return (
    <>
      <Routes>
        <Route path={comment} element={<Comment />} />
        <Route path={serviceAdvisorName} element={<ServiceAdvisorName />} />
        <Route path='*' element={<Navigate to={serviceAdvisorName} />} />
      </Routes>
      <GlobalStyles scaling={scaling} styles={advisorStyle} />
    </>
  );
}

export default Advisor;
