
import browserStorage from "../browserStorage"

function chooseRedirectPath(questionnaire, newValidPath) {

    const newValidPathIndex = questionnaire.findIndex(page => page.path === newValidPath)

    if (browserStorage.getProgressIndex() && parseInt(browserStorage.getProgressIndex()) < newValidPathIndex + 1) {
        const page = questionnaire[parseInt(browserStorage.getProgressIndex())]
        if (page) {
            return page.path
        }
    }
    return newValidPath
}

export default chooseRedirectPath
