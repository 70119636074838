import React, { useContext, useEffect, useState } from 'react';

import {
  Main,
  Legend,
  Heading,
  RadioGroup,
  Radio,
  GridContainer,
  GridColumn,
  NavBar,
  IconLink,
  ProgressionIndicator,
  IconButton,
  FAQ,
  Container,
  Loader,
  CompanyLogo
} from '@domain/components';
import { causeDamageCategories } from '@domain/constants';
import { useFaq, useRadio, useSaveIncidentFactory } from '@domain/hooks';
import { getPrevNextPagePath, goToNextPage, stripLastPathSegment, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { useNavigate, useLocation } from 'react-router-dom'
import routes from '../routes';
import { useSelector } from 'react-redux';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useTheme } from '@mui/material';

const causeDamage = 'causeDamage';
const vehicleSpeed = 'vehicleSpeed';

function CauseDamageAnders() {
  const navigate = useNavigate()
  const location = useLocation()
  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();

  const currentValue = incident[causeDamage];

  const themeContext = useTheme();

  const { causeDamageCategory } = routes;

  // not true. But this page doesn't exist on the questionnaire, so I am using the previous one as a reference to generate the next page
  const currentPath = '/onboarding/schade-beschrijven/oorzaak'

  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath)
      browserStorage.setProgress(progress.name);
      browserStorage.setProgressIndex(progress.index);
    }
  }, [currentPath, questionnaire])


  const [causeDamageSelected, setCauseDamageSelected] = useState(null);
  useEffect(() => {
    if (causeDamageSelected && incident.causeDamage) {
      goToNextPage(navigate, questionnaire, incident, currentPath, files)
    }
  }, [questionnaire, causeDamageSelected, incident, files])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  //// This useRadio is ok ( doenst use goToNextPage function)
  const [handleChange, isAutoFocus, selectedValue] = useRadio(
    currentValue,
    causeDamage,
    (value) => { /* don't do anything, wait for the incident to be update in the redux store */setCauseDamageSelected(value) },
    (value) => saveIncident({
      [causeDamage]: value,
      [vehicleSpeed]: '0-30',
    }, isPageBeforeSuccess),
  );

  if (!insurer || !questionnaire) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const prevPage = stripLastPathSegment(location.pathname) + causeDamageCategory;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: 'cause-damage',
    id: `damage-option-${id}`,
    defaultChecked: selectedValue === value,
    value,
    onClick: handleChange,
    radioClassName: 'radio--damage-cause',
    autoFocus: isAutoFocus(value, id),
    triggerKeys
  });

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible} />
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>
            <Heading level="1">{heading}</Heading>
          </GridColumn>

          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup>
              <Radio {...radio(1, causeDamageCategories.Anders[0], [49])}>{causeDamageCategories.Anders[0]}</Radio>
              <Radio {...radio(2, causeDamageCategories.Anders[1], [50])}>{causeDamageCategories.Anders[1]}</Radio>
              <Radio {...radio(3, causeDamageCategories.Anders[2], [51])}>{causeDamageCategories.Anders[2]}</Radio>
              <Radio {...radio(4, causeDamageCategories.Anders[3], [52])}>{causeDamageCategories.Anders[3]}</Radio>
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment>
  );
}

export default CauseDamageAnders;
