// NPM imports.
import React, { useMemo, useEffect, } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

// Bounded context imports.
import Welcome from './Welcome';
import Landing from './Landing';
import Personal from './Personal';
import Damage from './Damage';
import Recovery from './Recovery';
import Uploads from './Uploads'
import OwnBodyshop from './OwnBodyshop'
import Support from './Support';
import ServiceAdvisor from './ServiceAdvisor'
import NoConfigPage from './NoConfigPage'
// Routes for top level onboarding flow.
import routes from './routes';
import { useSelector } from 'react-redux';
import { useThemes } from '@domain/hooks';
import { Loader } from '@domain/components';
import { browserStorage, checkforValidPath, checkInitialProgress, checkIsFeedback, checkIsNewStatusCase } from '@domain/helpers';
import { companyNames, proccessType } from '@domain/constants';
import { useQuestionnaire } from '@domain/providers';

function Onboarding() {
  const location = useLocation()
  const navigate = useNavigate()
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)

  const { questionnaire } = useQuestionnaire();
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const themes = useMemo(() => [isThemed ? 'theme-custom' : 'theme-def'], [isThemed]);
  useThemes(themes);


  useEffect(() => {
    if (incident && insurer && questionnaire) {
      const isFeedback = checkIsFeedback(incident)
      const isNewStatusCase = checkIsNewStatusCase(incident)
      const inBodyshopChange = browserStorage.getInBodyshopChange()
      const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path

      const validPath = questionnaire && questionnaire.find(page => page.path === location.pathname)
      const redirect = !isNewStatusCase && (!isFeedback || (isFeedback && incident.portalStatus === 'Done')) && location.pathname !== newValidPath
      const lostInfo = validPath && isNewStatusCase && questionnaire.findIndex(page => page.path === location.pathname) > questionnaire.findIndex(page => page.path === newValidPath)

      const createdByExchange = incident && incident.createdBy === 'exchange'
      const schadegarantCase = insurer && insurer.objectType === proccessType.Schadegarant
      if ((redirect || lostInfo) && !schadegarantCase && !createdByExchange && !inBodyshopChange) {
        navigate(newValidPath, { replace: true })
      }
    }
  }, [incident, insurer, questionnaire])

  if (!incident || !insurer || !questionnaire) {
    return <Loader />;
  }

  const currentProgress = checkInitialProgress(incident, questionnaire)
  const { damage, personal, recovery, uploads, landing, welcome, ownbodyshop, help, serviceAdvisor, noConfig } = routes;


  return (
    <Routes>
      <Route path={noConfig} element={<NoConfigPage />} />
      <Route path={welcome} element={<Welcome />} />
      <Route path={landing} element={<Landing />} />
      <Route path={`${personal}/*`} element={<Personal />} />
      <Route path={`${damage}/*`} element={<Damage />} />
      <Route path={`${uploads}/*`} element={<Uploads />} />
      <Route path={`${ownbodyshop}/*`} element={<OwnBodyshop />} />
      <Route path={`${serviceAdvisor}/*`} element={<ServiceAdvisor />} />
      <Route path={`${recovery}/*`} element={<Recovery />} />
      <Route path={help} element={<Support />} />
      {!!questionnaire && questionnaire.length && questionnaire[currentProgress] && <Route path='*' element={<Navigate to={questionnaire[currentProgress].path} />} />}
    </Routes>
  );
}

export default Onboarding;
