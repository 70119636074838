import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Domain imports.
import {
  RadioGroup,
  Radio,
  NavBar,
  IconLink,
  ProgressionIndicator,
  IconButton,
  FAQ,
  GridColumn,
  GridContainer,
  Legend,
  Heading,
  Main,
  Container,
  Loader,
  CompanyLogo
} from '@domain/components';
import { useFaq, useSaveIncidentFactory, } from '@domain/hooks';
import { goToNextPage, getPrevNextPagePath, browserStorage, generatePageContent, chooseLogoSize } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'


function RadioPage({ fieldName, styleLabel, fields, className }) {
  const navigate = useNavigate()
  const { questionnaire } = useQuestionnaire();
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer);
  const files = useSelector(state => state.files)
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();
  const currentValue = incident[fieldName];
  const [selectedValue, setSelectedValue] = useState(currentValue);
  const [hasClicked, setHasClicked] = useState(false);
  const requestMade = useRef(false);

  const themeContext = useTheme();

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath)
      browserStorage.setProgress(progress.name);
      browserStorage.setProgressIndex(progress.index);
    }
  }, [currentPath, questionnaire])

  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath


  useEffect(() => {
    if (hasClicked && selectedValue === currentValue) {
      goToNextPage(navigate, questionnaire, incident, currentPath, files)
    }
  }, [currentValue, hasClicked, selectedValue, questionnaire, incident, currentPath, files]);

  async function handleChange(event, value) {
    event.preventDefault();
    if (value && !requestMade.current) {
      requestMade.current = true;
      setSelectedValue(value)
      const savedIncident = await saveIncident({ ...incident, [fieldName]: value }, isPageBeforeSuccess)
      if (savedIncident) {
        setHasClicked(true)
      }
    }
  }

  if (!insurer || !incident || !questionnaire || (questionnaire && !questionnaire.length)) {
    return <Loader />;
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible,
    className: 'main__damage',
    callBack: clickOutSideFaq,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: pagePath.prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage && nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const radio = (id, value, triggerKeys) => ({
    name: `${styleLabel}`,
    id: `${styleLabel}-option-${id}`,
    defaultChecked: selectedValue === value,
    value,
    onClick: handleChange,
    radioClassName: `radio--${styleLabel}`,
    triggerKeys
  });

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const useCustomLogo = content["use-custom-logo"]
  const legend = content["over-title-text"]
  const heading = content["title"]
  const faqs = content["faqs"]

  const middleLogo = {
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  }

  const chooseMiddleComponent = useCustomLogo
    ? <CompanyLogo {...middleLogo} />
    : <ProgressionIndicator steps={3} current={2} />

  return (
    <React.Fragment>
      {faqs && <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible} />}
      <Main {...main}>
        <NavBar>
          <Container className="icon-link-container">
            <IconLink {...iconLink([37])} />
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container className="faq-button-container">
            {faqs && <IconButton {...iconButton} />}
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn className="grid__column--main">
            <Legend>{legend}</Legend>

            <Heading level="1">{heading}</Heading>
          </GridColumn>
          <GridColumn
            justify="flex-end"
            className="grid__column--footer grid__column--radio-group"
          >
            <RadioGroup {...(className ? { className: className } : {})}>
              {fields.map((field, i) => {
                return (
                  <Radio key={i} {...radio(field.id, field.value, field.triggerKeys)}>{field.label}</Radio>
                )
              })}
            </RadioGroup>
          </GridColumn>
        </GridContainer>
      </Main>
    </React.Fragment >
  );
}

export default RadioPage;
