import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

// Domain imports.
import {
  Button,
  CompanyLogo,
  ConfirmationDialog,
  Container,
  FAQ,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  ImageViewer,
  ImageViewerContainer,
  InputFile,
  InstructionsList,
  Legend,
  Loader,
  Main,
  NavBar,
  Paragraph,
  ProgressionIndicator,
  SingleImageViewer,
  Text,
  UseIcon,
  ValidationFeedback,
} from '@domain/components';
import { goToNextPage, browserStorage, stripLastPathSegment } from '@domain/helpers';
import { HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';
import { useFaq, useEnterKey, useDragAndDrop, useFilesEndpoint, useScaling } from '@domain/hooks';
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import { Colors } from '@domain/theming';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .uploads__validation {
    min-height: 50px;
    margin-top: 0;
  }
  .image-uploads__title {
    flex: 1;
  }
  .image_uploads-feedback-comment {
    margin-bottom: 30px;
    margin-top: 15px;
    .h4 {
      color: ${Colors.RED}
    }
    .feedback-comment {
      margin-top: 0;  
      color: ${Colors.RED}
    }
  }
  .small__image {
    cursor: pointer;
    max-width: ${props => props.$scaling.scaleUpTo4K(250)}px;
    margin-top: ${props => props.$scaling.scaleUpTo4K(10)}px;
    height: auto;
    max-height: 180px; // IE bug -- big images brake from flex flow
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .screen-desktop & {
    .grid-container__main {
      justify-content: space-between;
    }
    .grid__column--main {
      flex: 0 1 50%;
      padding-right: ${props => props.$scaling.scaleUpTo4K(80)}px;
    }
    .grid__column--footer {
      align-items: stretch;
      max-width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      padding-top: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(50)}px;
      .image-upload__uploader-container {
          width: 100%;
          margin: 0 auto;
        }
    }
    .instructions--list {
      min-height: ${props => props.$scaling.scaleUpTo4K(300)}px;
    }
    .image-upload__button-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
    }
    .image-upload__title {
      // margin-top: 100px;
      // max-width: ${props => props.$scaling.scaleUpTo4K(480)}px;
      margin-bottom: auto;
    }
    .uploads__validation {
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
      min-height: ${props => props.$scaling.scaleUpTo4K(50)}px;
    }
  }

  @media screen and (max-width: 1028px)  {
    .screen-desktop & {
      .grid-container__main {
        padding-top: 30px;
      }
      .image-upload__title {
        margin: 0 auto;
      }
      .instructions--list {
        text-align: left;
        // max-width: 380px;
        margin: 60px auto 0;
        min-height: 320px;
      }
      .grid__column--main {
        padding-right: 0;
      }
      .grid__column--footer {
        flex-direction: column;
        padding-top: 0;
        padding-left: 0;
        .image-upload__uploader-container {
          max-width: 490px;
          width: 100%;
          margin: 0 auto;
        }
        .image-upload__button-container {
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          align-items: center;
        }
      }
    }
       .mobile {
          display: flex;
          flex-direction: column;
          align-items: center; 
          text-align: center;
      }
  }

  @media screen and (max-width: 580px)  {
    .screen-desktop & {
      .instructions--list {
        min-height: 250px;
        max-width: none;
        width: 100%;
      }
      .grid__column--footer {
        .image-upload__button-container {
          flex-direction: row;
          justify-content: flex-end;
            max-width: 100%;
        }
      }
    }
     .mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start; 
          text-align: left;
      }
  }
`;

const StyledPlusIcon = styled(UseIcon)`
  opacity: 0.5;
  color: ${Colors.GREY_DARKER};
`;

const StyledValidationFeedback = styled(ValidationFeedback)`
  display: block;
  text-align: center;
  .screen-desktop .grid__column--footer &.validation-feedback {
    width: auto;
  }
`;


function ImagesUploadPage({
  buttonText,
  instructions,
  legend,
  heading,
  filesSetName,
  currentPath,
  questionnaire,
  filesSet,
  feedbackCause,
  nextPage,
  prevPage,
  saveIncident,
  validationSchema,
  firstParagraph,
  secondParagraph,
  mobileParagraph,
  faqs,
  faqsMobile,
  isFeedback,
  files,
  incident,
  insurer,
  isPageBeforeSuccess,
  isVIP = false,
  imagesDisplayed,
  routes,
  showDesktopParagraphOnMobile = false,
  extraLeftBlock,
  notMultiple = false,
  dialogMobile,
  customHelpLink,
  helpLinkText,
  customMiddleLogo = false,
  middleLogo,
  maxImgSize = 10,
  maxNumberOfImages = 30,
  extraDamageImage = false
}) {
  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();
  const navigate = useNavigate()
  const {
    uploadImages,
    deleteFile,
    numOfUploads,
    setSelectedImage,
    largeFileUpload,
    nonImageUpload,
  } = useFilesEndpoint(filesSetName);

  const dispatch = useDispatch()
  const selectedImage = useSelector(state => state.selectedImage);

  const [errors, setErrors] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [bigFileUpload, setBigImageUpload] = useState(false);
  const [feedbackValidated, setFeedbackValidated] = useState(incident.feedbackValidated || false);
  const [maxNumberOfImagesDialogOpen, setMaxNumberOfImagesDialogOpen] = useState(false);

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath)
      browserStorage.setProgress(progress.name);
      browserStorage.setProgressIndex(progress.index);
    }
  }, [currentPath, questionnaire])

  const addUploads = useCallback(
    function addUploads(event) {
      event.preventDefault();
      const files = event.type === 'drop' ? event.dataTransfer.files : event.target.files
      if (files.length > maxNumberOfImages) {
        setMaxNumberOfImagesDialogOpen(true)
        return
      }
      uploadImages(files, maxImgSize, extraDamageImage);
    },
    [maxImgSize, uploadImages, maxNumberOfImages],
  );

  const isDragging = useDragAndDrop(addUploads);
  const nextButtonEnabled = !errors ? (isFeedback ? feedbackValidated : true) : false;

  useEffect(() => {
    async function checkForErrors() {
      if (isFeedback) {
        try {
          setErrors(null);
        } catch ({ errors }) {
          setErrors(errors);
        } finally {
          if (!feedbackValidated) {
            setErrors(err => err ? ['Upload tenminste 1 nieuwe foto om verder te gaan', ...err] : ['Upload tenminste 1 nieuwe foto om verder te gaan']);
          }
        }

      } else {
        try {
          await validationSchema.validate(filesSet, { abortEarly: true });
          setErrors(null);
        } catch ({ errors }) {
          setErrors(errors);
        }

      }
    }
    if (filesSet) {
      const feedbackFileUploaded = filesSet.find(document => document.status === 'FeedbackStored')
      if (feedbackFileUploaded) {
        setFeedbackValidated(true);
      }
      if (feedbackValidated && !feedbackFileUploaded) {
        setFeedbackValidated(false);
      }
    }
    checkForErrors();
  }, [feedbackValidated, filesSet, isFeedback, validationSchema]);

  async function handleOnClick() {
    setButtonClicked(true);
    if (!errors) {
      const incidentSaved = await saveIncident({ ...incident }, isPageBeforeSuccess)
      if (incidentSaved) {
        if (isFeedback && isPageBeforeSuccess) {
          const feedbackValidated = incidentSaved.feedbackValidated
          feedbackValidated
            ? goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback)
            : dispatch({ type: HTTP_ERROR_ENCOUNTERED, error: { message: 'Feedback not validated' } })
          return
        } else {
          goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback);
          return
        }
      }
    }
  }

  useEnterKey(handleOnClick, [errors]);

  useEffect(() => {
    if (selectedImage !== null) {
      if (filesSet === null || filesSet.length === 0) {
        setSelectedImage(null);
      } else if (filesSet !== null && !filesSet[selectedImage]) {
        setSelectedImage(null);
      }
    }
  }, [filesSet, selectedImage, setSelectedImage]);

  useEffect(() => {
    if (numOfUploads >= 1) {
      setTimeout(() => setBigImageUpload(true), 5000);
    }
    if (numOfUploads < 1 && !!bigFileUpload) {
      setBigImageUpload(false);
    }
  }, [numOfUploads, bigFileUpload])

  if (!filesSet || !insurer || !incident || !questionnaire) {
    return <Loader />;
  }

  function showDeletePage() {
    const { inspect } = routes;
    navigate(stripLastPathSegment(currentPath) + inspect);
  }

  function clickMain(event) {
    clickOutSideFaq(event);
    setSelectedImage(null);
  }

  function renderImage(image, i, errors) {
    const selected = i === selectedImage && (i !== 5 || filesSet.length === 5);
    // TODO: Clean this up
    const extraImages =
      filesSet.length >= 5
        ? i === 4
          ? Math.max(filesSet.length + numOfUploads - 5, 0)
          : 0
        : 0;
    const loading = () => {
      if (numOfUploads === 0) {
        return false;
      }
      if (i === 4 && filesSet.length >= 5) {
        return true;
      }
      if (!image.content && i < filesSet.length + numOfUploads) {
        return true;
      }
    };

    const { cpID, content } = image;
    return (
      <ImageViewer
        src={content || null}
        key={cpID || i}
        onClick={() => {
          setSelectedImage(i);
        }}
        htmlFor={!content ? 'input-file' : ''}
        selected={selected}
        clickDelete={() => deleteFile(image)}
        clickView={() => showDeletePage(i)}
        extraImages={extraImages}
        dulled={selectedImage !== null && selected !== i}
        loading={loading()}
        isDragging={isDragging}
      />
    );
  }

  function renderSingleImage(image, i, errors) {
    const selected = i === selectedImage
    // TODO: Clean this up
    const loading = () => {
      if (numOfUploads === 0) {
        return false;
      }
      if (i === 0 && filesSet.length >= 1) {
        return true;
      }
      if (!image.content && i < filesSet.length + numOfUploads) {
        return true;
      }
    };

    const { cpID, content, fileName } = image;

    return (
      <SingleImageViewer
        src={content || null}
        fileName={fileName}
        key={cpID || i}
        onClick={() => {
          setSelectedImage(i);
        }}
        htmlFor={!content ? 'input-file' : ''}
        selected={selected}
        clickDelete={() => {
          deleteFile(image);
        }}
        clickView={() => showDeletePage(i)}
        dulled={selectedImage !== null && selected !== i}
        loading={loading()}
        isDragging={isDragging}
      />
    );
  }
  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible || selectedImage !== null || isDragging,
    className: isDragging
      ? 'main__uploads main__is-dragging'
      : 'main__uploads',
    callBack: clickMain,
    $scaling: scaling,
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };

  const modifyInstruction = (instruction, i) => ({
    ...instruction,
    title: `Foto ${i + 1}: ${instruction.title}`,
  });

  const chooseMiddleComponent = customMiddleLogo
    ? <CompanyLogo {...middleLogo} />
    : isFeedback
      ? <Container className="empty-div" />
      : <ProgressionIndicator steps={3} current={2} />

  if (isMobile) {
    return (
      <React.Fragment>
        <FAQ
          faqListProps={faqListProps}
          faqs={faqsMobile || faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
        <StyledMain {...main}>
          <NavBar>
            <Container className="icon-link-container">
              {prevPage && <IconLink {...iconLink([37])} />}
              {nextPage && <IconLink {...iconLinkForward([39])} />}
            </Container>
            {chooseMiddleComponent}
            <Container className="faq-button-container">
              <IconButton {...iconButton} size="large" />
            </Container>
          </NavBar>
          <GridContainer main={true}>
            <GridColumn className="image-uploads__title mobile">
              <Legend>{legend}</Legend>
              <Heading level="1">{heading}</Heading>
              {!!feedbackCause &&
                <Container className="image_uploads-feedback-comment">
                  <Heading level="4">Opmerking van de hersteller</Heading>
                  <Paragraph className="feedback-comment">
                    {feedbackCause}
                  </Paragraph>
                </Container>}
              {showDesktopParagraphOnMobile &&
                <React.Fragment>
                  <Paragraph>
                    {firstParagraph}
                  </Paragraph>
                  {secondParagraph && <Paragraph>
                    {secondParagraph}
                  </Paragraph>}
                </React.Fragment>
              }
              {mobileParagraph && <Paragraph
                onClick={handleOnHelpClick}
                className="image-uploads__help"
              >
                {mobileParagraph}
              </Paragraph>}
              {dialogMobile && dialogMobile}
            </GridColumn>

            <GridColumn

              justify="flex-end"
              className="grid__column--main"
            >
              <ImageViewerContainer>
                {imagesDisplayed.map(notMultiple ? renderSingleImage : renderImage)}
              </ImageViewerContainer>
              <InputFile onChange={addUploads} {...(notMultiple ? { multiple: false } : {})} accepts={"image/jpeg,image/jpg,image/png"}>
                <UseIcon name="icon-file" />
                <Text>
                  <b>Maak een foto om te uploaden</b>
                </Text>
                <Text>Of kies uit de bibliotheek</Text>
              </InputFile>
              <Container className="uploads__validation">
                {errors &&
                  errors.map(error => (
                    <StyledValidationFeedback type={buttonClicked ? 'error' : 'info'} key={error}>{error}</StyledValidationFeedback>
                  ))}
                {largeFileUpload && (
                  <StyledValidationFeedback>
                    Foto's moeten {maxImgSize}MB of minder zijn
                  </StyledValidationFeedback>
                )}
                {nonImageUpload && (
                  <StyledValidationFeedback>
                    Upload alleen jpg- of png-bestanden
                  </StyledValidationFeedback>
                )}
                {bigFileUpload && numOfUploads < 1 && (
                  <StyledValidationFeedback>
                    Even geduld a.u.b.<br /><br />
                    Het uploaden van grote bestanden kan even duren.
                  </StyledValidationFeedback>
                )}
              </Container>
            </GridColumn>
            <GridColumn className="grid__column--footer">
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
            </GridColumn>
          </GridContainer>
        </StyledMain>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <ConfirmationDialog
        content={`U kunt maximaal ${maxNumberOfImages} foto's uploaden`}
        onYesClick={() => setMaxNumberOfImagesDialogOpen(false)}
        open={maxNumberOfImagesDialogOpen}
        yesLabel="Bevestigen"
        title=""
        showNoButton={false}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            {prevPage && <IconLink {...iconLink([37])} />}
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container
            className="faq-button-container"
            onClick={handleOnHelpClick}
          >
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn

            justify="space-evenly"
            className="grid__column--main"
          >
            <Container className="image-upload__title">
              <Legend>{legend}</Legend>
              <Heading level="1">{heading}</Heading>
              {!!feedbackCause &&
                <Container className="image_uploads-feedback-comment">
                  <Heading level="4">Opmerking van de hersteller</Heading>
                  <Paragraph className="feedback-comment">
                    {feedbackCause}
                  </Paragraph>
                </Container>}
              <Paragraph>
                {firstParagraph}
              </Paragraph>
              {secondParagraph && <Paragraph>
                {secondParagraph}
              </Paragraph>}
            </Container>
            {instructions && <InstructionsList
              instructions={instructions.map(modifyInstruction)}
            />}
            {extraLeftBlock && extraLeftBlock}
          </GridColumn>
          <GridColumn
            justify="flex-end"
            className="grid__column--footer"
          >
            <Container className="image-upload__uploader-container">
              <ImageViewerContainer>
                {imagesDisplayed.map(notMultiple ? renderSingleImage : renderImage)}
              </ImageViewerContainer>
              <InputFile onChange={addUploads} isDragging={isDragging} {...(notMultiple ? { multiple: false } : {})} accepts={"image/jpeg,image/jpg,image/png"}>
                {isDragging ? (
                  <StyledPlusIcon
                    name="upload-plus"
                    height={80}
                    width={80}
                    className="uploads__input__plus"
                  />
                ) : (
                  <>
                    <UseIcon name="icon-file" />
                    <Text>
                      <b>Drag & drop om te uploaden</b>
                    </Text>
                    <Text>Of kies uit de bibliotheek</Text>
                  </>
                )}
              </InputFile>
              <Container className="uploads__validation">
                {errors &&
                  errors.map(error => {
                    if (typeof error === 'object') {
                      return (
                        <StyledValidationFeedback
                          type={'error'}
                          key={error.message}
                        >
                          {error.message}
                        </StyledValidationFeedback>
                      )
                    }
                    return (
                      <StyledValidationFeedback
                        type={buttonClicked ? 'error' : 'info'}
                        key={error}
                      >
                        {error}
                      </StyledValidationFeedback>
                    )
                  })}
                {largeFileUpload && (
                  <StyledValidationFeedback>
                    Foto's moeten {maxImgSize}MB of minder zijn
                  </StyledValidationFeedback>
                )}
                {nonImageUpload && (
                  <StyledValidationFeedback>
                    Upload alleen jpg- of png-bestanden
                  </StyledValidationFeedback>
                )}
                {bigFileUpload && (
                  <StyledValidationFeedback>
                    Even geduld a.u.b.<br /><br />
                    Het uploaden van grote bestanden kan even duren.
                  </StyledValidationFeedback>
                )}
              </Container>
            </Container>
            <Container className="image-upload__button-container">
              {' '}
              <Button {...button}>
                Volgende
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
              <Text className="of--enter">
                of <b>ENTER</b>
              </Text>
            </Container>
          </GridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  );

}

export default ImagesUploadPage;
