
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Domain imports.
import { Container, Form, Input, Loader, ValidationFeedback } from '@domain/components';
import { useFormHandle, useSaveIncidentFactory } from '@domain/hooks';
import { getPrevNextPagePath, goToNextPage, checkIsFeedback } from '@domain/helpers';
import { useQuestionnaire } from '@domain/providers';
import { HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';
import { useNavigate, useLocation } from 'react-router-dom'

import { validationSchema, validationSchemaTextInput } from './validation-schema';
import { isMobile } from 'react-device-detect';
import { FilesUploadPage } from '@domain/pages';

function Offer() {

  const navigate = useNavigate()
  const insurer = useSelector(state => state.insurer)
  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)
  const offers = files.offers

  const { questionnaire } = useQuestionnaire()

  const dispatch = useDispatch()

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);

  const isFeedback = checkIsFeedback(incident)
  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  async function onValidated(values) {
    const calculationFormatted = values.calculationTotalPriceInc.toString().replace(',', '.')
    const calculationValue = parseFloat(calculationFormatted)
    const incidentSaved = await saveIncident({ ...incident, ...values, calculationTotalPriceInc: calculationValue }, isPageBeforeSuccess)
    if (incidentSaved) {
      if (isFeedback && isPageBeforeSuccess) {
        const feedbackValidated = incidentSaved.feedbackValidated
        feedbackValidated
          ? goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback)
          : dispatch({ type: HTTP_ERROR_ENCOUNTERED, error: { message: 'Feedback not validated' } })
        return
      } else {
        goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback);
        return
      }
    }
  }
  const form = {
    id: 'calculationTotalPriceInc'
  };

  const field = {
    name: 'calculationTotalPriceInc',
    label: 'Totale reparatiebedrag',
  }

  const {
    errors: calcFieldErrors,
    values,
    handleSubmit,
    handleChange,
    buttonClicked: buttonClickedCalcField,
  } = useFormHandle(
    validationSchemaTextInput,
    { [field.name]: incident.calculationTotalPriceInc || '' },
    onValidated,
  );

  const faqs = useMemo(
    () => [
      {
        question: 'Waarom moet ik het schadebedrag en de offerte uploaden?',
        answer: 'Dit hebben wij nodig voor de verdere afhandeling van uw dossier.',

      }
    ], []);

  if (!offers || !insurer || !incident || !questionnaire) {
    return <Loader />;
  }


  async function handleOnClick(event) {
    event.preventDefault()
    handleSubmit(event)
  }

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files,
    isFeedback
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  function feedbackCause() {
    const page = insurer.configuration.find(page => page.name === 'offer')
    const feedbacktext = page.feedbacktext ? decodeURIComponent(page.feedbacktext) : null
    return feedbacktext
  }

  const formInput = ({ name, label, displayLabel = true }) => ({
    type: 'text',
    name,
    id: name,
    valid: !calcFieldErrors[name] || !buttonClickedCalcField,
    onChange: handleChange,
    label,
    regexPass: !calcFieldErrors[name],
    value: values[name],
    displayLabel,
    customBaseSize: 24,
    transparentAdornment: true,
    multiline: true,
  });

  const nextButtonEnabled = !calcFieldErrors[field.name]

  const extraFormField = (
    <Container className="image-upload__title">
      <Form {...form} >
        <Container key={formInput.name} className="input-custom">
          <Input
            {...formInput(field)}
            autoFocus={!isMobile}
          />
          <Container className="validation__container__custom">
            {buttonClickedCalcField && calcFieldErrors[field.name] && (
              <ValidationFeedback className={'validatition-feedback-custom'} type="error" custom={true}>{calcFieldErrors[field.name]}</ValidationFeedback>
            )}
          </Container>
        </Container>
      </Form>
    </Container>
  )

  const legend = 'Contactgegevens en offerte'

  const heading = 'Offerte'
  const firstParagraph = `Upload de offerte of calculatie van uw herstelbedrijf en vul het totale reparatiebedrag in.`
  const secondParagraph = (<><b>Let op:</b> het is belangrijk dat u een complete en goed leesbare offerte of calculatie uploadt.</>)

  return (
    <FilesUploadPage
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      buttonText="Volgende"
      currentPath={currentPath}
      filesSetName="offers"
      filesSet={offers}
      feedbackCause={feedbackCause()}
      validationSchema={validationSchema}
      faqs={faqs}
      legend={legend}
      heading={heading}
      firstParagraph={firstParagraph}
      secondParagraph={secondParagraph}
      files={files}
      incident={incident}
      insurer={insurer}
      isPageBeforeSuccess={isPageBeforeSuccess}
      showExtraFormField={true}
      extraFormField={extraFormField}
      customHandleOnClick={handleOnClick}
      customNextButtonEnabled={nextButtonEnabled}
      showDesktopParagraphOnMobile={true}
      isFeedback={isFeedback}
    />
  );
}

export default Offer
