// NPM imports.
import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

// Bounded context imports.
import Start from './Start';
// import Bodyshop from './Bodyshop';
import Success from './Success';
import ThankYou from './ThankYou'
import End from './End'

// Routes for personal flow.
import routes from './routes';
import { useSelector } from 'react-redux';
import { Loader } from '@domain/components';
import { proccessType } from '@domain/constants'
import EndOfFlow from './EndOfFlow';

import { useQuestionnaire } from '@domain/providers';
import { checkforValidPath, chooseRedirectPath } from '@domain/helpers';
import { useLocation } from 'react-router-dom';
// import { NoBodyshop } from '../ProcessError';

function Recovery() {
  const insurer = useSelector(state => state.insurer)
  const isCasco = insurer.objectType === proccessType.Casco
  const location = useLocation()

  const { questionnaire } = useQuestionnaire();

  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)

  const validPath = !!questionnaire && !!questionnaire.find(page => page.path === location.pathname)
  const newValidPath = checkforValidPath(incident, questionnaire, files, insurer).path


  const { start, success, thankYou, end, endOfFlow } = routes;
  if (!insurer) {
    return <Loader />;
  }
  if (!!questionnaire && questionnaire.length && !validPath) {
    return <Navigate to={chooseRedirectPath(questionnaire, newValidPath)} replace={true} />
  }
  return (
    <Routes>
      <Route path={start} element={<Start />} />
      <Route path={success} element={<Success />} />
      <Route path={thankYou} element={<ThankYou />} />
      <Route path={end} element={<End />} />
      <Route path={endOfFlow} element={<EndOfFlow />} />
      <Route path='*' Element={<Navigate to={isCasco ? start : success} />} />
    </Routes>
  );
}

export default Recovery;
