import React from 'react';
import { useSelector } from 'react-redux';

import { validationSchema, validationSchemaNotRequired } from './validation-schema';

// Domain imports.
import { Loader } from '@domain/components';
import { FilesUploadPage } from '@domain/pages'
import { useSaveIncidentFactory, } from '@domain/hooks';
import { getPrevNextPagePath, generatePageContent, checkIsFeedback } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useLocation } from 'react-router-dom';

function Saf() {


  const insurer = useSelector(state => state.insurer)
  const incident = useSelector(state => state.incident);
  const files = useSelector(state => state.files)
  const safDocuments = files.safDocuments

  const { questionnaire } = useQuestionnaire()

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior);


  if (!safDocuments || !insurer || !incident || !questionnaire) {
    return <Loader />;
  }

  const isFeedback = checkIsFeedback(incident)
  const isPageBeforeSuccess = questionnaire[questionnaire.length - 2].path === currentPath

  const pagePath = getPrevNextPagePath(
    questionnaire,
    incident,
    currentPath,
    files,
    isFeedback
  );
  const nextPage = pagePath.nextPage !== currentPath
    ? pagePath.nextPage
    : undefined;

  function feedbackCause() {
    const page = questionnaire.find(page => page.name === 'saf')
    const feedbacktext = page.feedbacktext ? decodeURIComponent(page.feedbacktext) : null
    return feedbacktext
  }


  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, false, pageName)

  const legend = content["over-title-text"]
  const heading = content["title"]
  const firstParagraph = content["intro-text"]
  const secondParagraph = content["second-text"]
  const firstParagraphRequired = content["intro-text-required"]
  const secondParagraphRequired = content["second-text-required"]
  const customHelpLink = content["custom-help-link"]
  const helpLinkText = content["help-link-text"]
  const faqs = content["faqs"]
  const validationText = content["validation-text"]

  const validationSchemaToBeUsed = () => {
    if (page.required === false) {
      return validationSchemaNotRequired
    }
    return validationSchema(validationText)
  }

  return (
    <FilesUploadPage
      nextPage={nextPage}
      prevPage={pagePath.prevPage}
      questionnaire={questionnaire}
      saveIncident={saveIncident}
      buttonText="Volgende"
      currentPath={currentPath}
      filesSetName="safDocuments"
      filesSet={safDocuments}
      feedbackCause={feedbackCause()}
      validationSchema={validationSchemaToBeUsed()}
      faqs={faqs}
      legend={legend}
      heading={heading}
      firstParagraph={page && page.required === true ? firstParagraphRequired : firstParagraph}
      secondParagraph={page && page.required === true ? secondParagraphRequired : secondParagraph}
      files={files}
      incident={incident}
      insurer={insurer}
      isFeedback={isFeedback}
      isPageBeforeSuccess={isPageBeforeSuccess}
      showDesktopParagraphOnMobile={true}
      customHelpLink={customHelpLink}
      helpLinkText={helpLinkText}
    />
  );
}

export default Saf
